export const GET_AAL_MESSAGES_ID = 'getAllMessagesByChatId';
export const GET_ALL_CHATS_ID = 'getAllChatsById';
export const GET_ALL_USERS = 'getAllUsers';
export const GET_ALL_COMMUNITIES = 'getAllCommunities';
export const GET_CHAT_BY_CHATID = 'getChatByChatId';
export const GET_ALL_POSTS_BYID_COMMUNITY = 'getAllPostsByidCommunity';
export const GET_ALL_USERS_ADMIN = 'getAllUsersAdmin';
export const GET_INVITATIONS_BY_COMMUNITY_ID = 'getInvitationsByCommunityId';
export const GET_INVITATIONS_BY_INVITEE_ID = 'getInvitationsByInviteeId';
export const GET_USER_BY_ID = 'getUserById';
export const GET_ALL_EXISTING_CALENDAR = 'getAllExistingCalendar';
export const GET_EVENT_BY_CALENDAR = 'getEventByCalendar';
export const GET_USER_BY_FIREBASE_ID = 'getUserByFirebaseId';
