import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_EVENT_BY_CALENDAR} from '../../constants/queryKeys';

export const useGetEventByCalendarQuery = (calendarId: string, grantId:string) => {
  return useQuery({
    queryKey: [GET_EVENT_BY_CALENDAR, calendarId],
    queryFn: () => apiHandlers.connectCalendar.getEventByCalendarId(calendarId, grantId ).then(data => data.data),
    enabled: !!grantId && !!calendarId
  });
};
