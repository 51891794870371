import { useLinkTo } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Image, View } from 'react-native';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../../components/Button';
import Text from '../../../components/Text';
import { calendarStateSelector, userStateSelector } from '../../../redux/Store';
import { Colour } from '../../../styles/style';
import calendarTick from '../../../assets/images/iconCalendarCircleTick@3x.png';
import { CalendarEvent } from '../../../redux/Calendar';
import ConnectCalendar from './ConnectCalendar';
import { logAnalyticsEvent } from '../../../services/Firebase';
import { useGetUserByIdQuery } from '../../../api/queries/useGetUserByIdQuery';
import { CircularProgress, Box } from '@mui/material';
import { useUserDataMutation } from '../../../api/mutations/useUserDataMutation';

interface Props {
  loading: boolean;
  onComplete(): void;
}

const CreateListingStep4 = (props: Props) => {
  const { loading, onComplete } = props;
  const linkTo = useLinkTo();
  const [idUserLocal, setIdUserLocal] = useState<any>();
  const calendarState = useSelector(calendarStateSelector);
  const userState = useSelector(userStateSelector);
  const { userDataMutate } = useUserDataMutation();

  const userId = localStorage.getItem('userId') || 0;

  const handleComplete = () => {
    if (!calendarState.selectedCalendar || !!userdata?.grantId) {
      logAnalyticsEvent('calendar_connection_skipped', {});
    }

    if (userState.listing?.imageUrl) {
      userDataMutate({
        id: +userId,
        data: { photo: userState.listing.imageUrl },
      });
    }

    onComplete();
  };

  const { data: userdata, isLoading } = useGetUserByIdQuery(+idUserLocal, idUserLocal);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setIdUserLocal(parseInt(storedUserId, 10));
    }
  }, []);

  const connectedCalendarComponent = () => {
    return (
      <View style={{ flex: 1, justifyContent: 'space-evenly' }}>
        <View>
          <Image style={{ width: 135, height: 135, alignSelf: 'center' }} source={calendarTick} />
          <Text style={{ color: '#757575', fontSize: 20, textAlign: 'center', marginTop: 30, marginBottom: 25 }}>
            CALENDAR CONNECTED
          </Text>

          <Text fontFamily="Barlow-Bold" style={{ color: Colour.DarkBlue, fontSize: 24, textAlign: 'center' }}>
            {calendarState?.selectedCalendar?.title}
          </Text>
        </View>
        <PrimaryButton
          containerStyle={{ borderRadius: 25 }}
          buttonStyle={{
            height: 50,
          }}
          titleStyle={{
            fontFamily: 'Barlow',
            fontSize: 18,
            letterSpacing: 1.5,
          }}
          title="CHOOSE DIFFERENT CALENDAR"
          onPress={() => linkTo('/choosecalendar')}
        />
      </View>
    );
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        {calendarState.selectedCalendar && !!userdata?.grantId ? connectedCalendarComponent() : <ConnectCalendar />}
      </View>

      <PrimaryButton loading={loading} disabled={loading} title="COMPLETE" onPress={handleComplete} />
    </>
  );
};

export default CreateListingStep4;
