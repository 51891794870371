import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Avatar,
  Button,
  useMediaQuery,
  IconButton,
  TextField,
  Collapse,
  Menu,
  MenuItem,
  Box,
  Modal,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import iconContact from '../../../assets/images/icon-contact.png';
import socket from '../../../api/socket/socket';
import { Colour } from '../../../styles/style';
import Linkify from 'react-linkify';

interface Post {
  content: string;
  image: string | null;
  timestamp: string;
}

interface PostListProps {
  posts: any;
  isPermissionAdmin?: boolean | null | '';
  isCommunityMember?: boolean | null | '';
}

const PostList: React.FC<PostListProps> = ({ posts, isPermissionAdmin, isCommunityMember }) => {
  const isMobile = useMediaQuery('(max-width:990px)');
  const storedUserId = parseInt(localStorage.getItem('userId') || '', 10);
  const [newComment, setNewComment] = useState<string>('');
  const [openComments, setOpenComments] = useState<Record<number, boolean>>({});
  const [commentInEdit, setCommentInEdit] = useState<number | null>(null);
  const [updatedCommentText, setUpdatedCommentText] = useState<string>('');

  const [postInEdit, setPostInEdit] = useState<number | null>(null);
  const [updatedPostText, setUpdatedPostText] = useState<string>('');

  const handleEditPost = (post: Post) => {
    setPostInEdit(post.id);
    setUpdatedPostText(post.text);
  };

  const handleSaveEditedPost = (postId: number) => {
    const dataEditPost = {
      postId,
      updatePostDto: {
        text: updatedPostText,
      },
    };

    socket.emit('editPost', dataEditPost);

    setPostInEdit(null);
    setUpdatedPostText('');
  };

  const isDeletePhotoVisible = (creatorId: number) =>
    isPermissionAdmin || +localStorage.getItem('userId') === creatorId;

  console.log(isDeletePhotoVisible(29), 'isDeletePhotoVisible');

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState<number>();

  const handleMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedPostId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    setAnchorEl(null);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPostId(null);
  };

  const deletePost = (id: number) => {
    const dataDeletePost = { postId: id };
    socket.emit('deletePost', dataDeletePost);
  };

  const handlePhoto = (id: number, text: string) => {
    const dataEditPost = {
      postId: id,
      updatePostDto: {
        files: [],
      },
    };

    !text.trim() ? socket.emit('deletePost', { postId: id }) : socket.emit('editPost', dataEditPost);
  };

  const linkDecorator = (href: string, text: string, key: number) => (
    <a
      href={href}
      key={key}
      style={{
        color: '#007bff',
        textDecoration: 'none',
        fontWeight: 'bold',
      }}
      target="_blank"
      rel="noopener noreferrer">
      {text}
    </a>
  );

  const handleDeleteComment = (commentId: number) => {
    socket.emit('deleteComment', { commentId });
  };

  const formatMessageContent = content => {
    return content.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const handleAddComment = (postId: number) => {
    if (newComment) {
      const commentData = {
        createCommentDto: {
          postId: postId,
          creatorId: storedUserId,
          text: newComment,
        },
      };

      socket.emit('createComment', commentData);
      setNewComment('');
    }
  };

  const handleImageUpload = (event, postId) => {
    const file = event.target.files[0];

    console.log(postId);

    if (file) {
      console.log(`Selected file: ${file.name}`);

      const formData = new FormData();
      formData.append('file', file);

      const reader = new FileReader();
      reader.onload = () => {
        const fileBase64 = reader.result;

        const dataEditPost = {
          postId: postId,
          updatePostDto: {
            files: [fileBase64],
          },
        };

        socket.emit('editPost', dataEditPost);
        console.log('File sent via socket:', dataEditPost);
      };

      reader.onerror = error => {
        console.error('Error reading file:', error);
      };

      reader.readAsDataURL(file);
    }

    handleMenuClose();
  };

  const toggleComments = (postId: number) => {
    setOpenComments(prevState => {
      const newOpenComments = Object.keys(prevState).reduce(
        (acc, id) => {
          acc[Number(id)] = false;
          return acc;
        },
        {} as Record<number, boolean>,
      );

      return {
        ...newOpenComments,
        [postId]: !prevState[postId],
      };
    });

    setNewComment('');
  };

  const handleLike = (postId: number, nameSocket: string) => {
    const dataLikePost = {
      postId,
      userId: storedUserId,
    };
    socket.emit(nameSocket, dataLikePost);
  };

  const handleEditComment = comment => {
    setCommentInEdit(comment.id);
    setUpdatedCommentText(comment.text);
  };

  const handleSaveEditedComment = (commentId, postId) => {
    const dataEditComment = {
      commentId,
      updateCommentDto: {
        postId,
        creatorId: storedUserId,
        text: updatedCommentText,
      },
    };

    socket.emit('editComment', dataEditComment);

    setCommentInEdit(null);
    setUpdatedCommentText('');
  };

  const checkPost = (idPost: any) => {
    const postById = posts.filter(post => post.id === idPost);
    const isLike = postById?.length > 0 && postById[0].likes && postById[0].likes.includes(storedUserId);

    return isLike;
  };

  return (
    <div style={{ marginTop: '30px', width: isMobile ? '95%' : '50%', marginLeft: '20px' }}>
      {posts.length > 0 ? (
        isCommunityMember || isPermissionAdmin ? (
          posts.map((post, index) => (
            <Paper
              key={index}
              elevation={2}
              sx={{
                padding: '15px',
                marginBottom: '20px',
                borderRadius: '8px',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    src={post.sender.photo?.length > 0 ? post.sender.photo : iconContact}
                    sx={{
                      marginRight: '10px',
                      backgroundColor: 'lightblue',
                    }}
                  />
                  <div>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {post.sender.username}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formatDate(post.createdAt)}
                    </Typography>
                  </div>
                </div>

                <div>
                  {post.creatorId === storedUserId && (
                    <>
                      {postInEdit === post.id ? (
                        <IconButton
                          onClick={() => handleSaveEditedPost(post.id)}
                          color="primary"
                          sx={{ marginLeft: 'auto' }}>
                          <SaveIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handleEditPost(post)} color="primary" sx={{ marginLeft: 'auto' }}>
                          <EditIcon />
                        </IconButton>
                      )}
                    </>
                  )}

                  {(post.creatorId === storedUserId || isPermissionAdmin) && (
                    <IconButton onClick={() => deletePost(post.id)} sx={{ marginLeft: 'auto' }} color="error">
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              </div>

              <div>
                {postInEdit === post.id ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={updatedPostText}
                    multiline
                    onChange={e => setUpdatedPostText(e.target.value)}
                    sx={{ marginBottom: '10px' }}
                  />
                ) : (
                  <Linkify componentDecorator={linkDecorator}>
                    <Typography
                      sx={{
                        marginBottom: '5px',
                        marginTop: '5px',
                        wordBreak: 'break-word',
                      }}>
                      {formatMessageContent(post.text)}
                    </Typography>
                  </Linkify>
                )}
              </div>

              {post?.files?.length > 0 && (
                <div style={{ position: 'relative' }}>
                  <img
                    src={post.files[0]}
                    alt="Post"
                    style={{
                      width: '100%',
                      height: '400px',
                      objectFit: 'contain',
                      borderRadius: '8px',
                      marginTop: '10px',
                      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                  {isDeletePhotoVisible(post.creatorId) && (
                    <IconButton
                      onClick={event => handleMenuClick(event, post.id)}
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'white',
                      }}>
                      <MoreVertIcon color="action" />
                    </IconButton>
                  )}

                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem>
                      Edit Photo
                      <input
                        type="file"
                        style={{
                          opacity: 0,
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                        }}
                        onChange={event => handleImageUpload(event, selectedPostId)}
                      />
                    </MenuItem>
                    <MenuItem onClick={handleOpenModal}>Delete Photo</MenuItem>
                  </Menu>
                </div>
              )}
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="delete-photo-modal"
                aria-describedby="confirm-delete-photo">
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 350,
                    bgcolor: 'background.paper',
                    border: 'none',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '18px',
                  }}>
                  <Typography id="confirm-delete-photo" textAlign="center" sx={{ mb: 3, fontSize: '14px' }}>
                    Are you sure you want to delete this photo?
                    <br />
                    This action cannot be undone.
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handlePhoto(selectedPostId, post.text);
                        handleCloseModal();
                      }}
                      sx={{ px: 3 }}>
                      Delete
                    </Button>
                    <Button variant="outlined" onClick={handleCloseModal} sx={{ px: 3 }}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Modal>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <Button
                  startIcon={<ThumbUpIcon style={{ color: checkPost(post.id) ? Colour.Blue : Colour.LightBlue }} />}
                  onClick={() =>
                    checkPost(post.id) ? handleLike(post.id, 'unlikePost') : handleLike(post.id, 'likePost')
                  }
                  sx={{ textTransform: 'none', marginRight: '10px' }}>
                  {post.likes && post.likes.length} Likes
                </Button>

                <Button onClick={() => toggleComments(post.id)} sx={{ textTransform: 'none' }}>
                  {openComments[post.id] ? 'Hide Comments' : 'Show Comments'}
                </Button>
              </div>
              <Collapse in={openComments[post.id]} timeout="auto" unmountOnExit>
                <div style={{ marginTop: '20px' }}>
                  <Typography variant="subtitle2" fontWeight="bold" color="primary">
                    Comments
                  </Typography>
                  {post.comments &&
                    post.comments.map(comment => (
                      <Paper
                        key={comment.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '10px',
                          marginTop: '10px',
                          borderRadius: '8px',
                          backgroundColor: '#f9f9f9',
                        }}>
                        <Avatar
                          src={comment.creator.photo ? comment.creator.photo : iconContact}
                          sx={{ marginRight: '10px', backgroundColor: 'lightblue' }}
                        />
                        <div style={{ flex: 1 }}>
                          <Typography variant="body2" fontWeight="bold" color="textPrimary">
                            {comment.creator.username}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {formatDate(comment.createdAt)}
                          </Typography>
                          {commentInEdit === comment.id ? (
                            <TextField
                              fullWidth
                              size="small"
                              value={updatedCommentText}
                              onChange={e => setUpdatedCommentText(e.target.value)}
                              sx={{ marginTop: '5px' }}
                            />
                          ) : (
                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: '5px' }}>
                              {comment.text}
                            </Typography>
                          )}
                        </div>
                        {(comment.creatorId === storedUserId || isPermissionAdmin) && (
                          <>
                            {commentInEdit === comment.id ? (
                              <IconButton
                                onClick={() => handleSaveEditedComment(comment.id, post.id)}
                                color="primary"
                                sx={{ marginLeft: 'auto' }}>
                                <SaveIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => handleEditComment(comment)}
                                color="primary"
                                sx={{ marginLeft: 'auto' }}>
                                <EditIcon />
                              </IconButton>
                            )}
                            <IconButton onClick={() => handleDeleteComment(comment.id)} sx={{ color: 'red' }}>
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                      </Paper>
                    ))}

                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <TextField
                      label="Write a comment..."
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={newComment}
                      onChange={e => setNewComment(e.target.value)}
                      sx={{
                        marginRight: '10px',
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '20px',
                          padding: '5px 10px',
                        },
                      }}
                    />
                    <Button variant="contained" onClick={() => handleAddComment(post.id)} sx={{ borderRadius: '20px' }}>
                      Add
                    </Button>
                  </div>
                </div>
              </Collapse>
            </Paper>
          ))
        ) : null
      ) : (
        <Typography variant="body1" color="textSecondary">
          No posts yet. Be the first to post!
        </Typography>
      )}
    </div>
  );
};

export default PostList;
