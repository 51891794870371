import React, { useState } from 'react';
import { TextField, IconButton, Avatar, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useGetAllChatByIdQuery, useGetAllUsersQuery } from '../../api/queries';
import { useChatmutations, useUserDataMutation } from '../../api/mutations';
import { Socket, io } from 'socket.io-client';
import iconContact from '../../assets/images/icon-contact.png';
import { Barlow } from '../../styles/style';
import socket from '../../api/socket/socket';

interface Props<T = {}> {
  isCancelSubscription: boolean;
  onClose: () => void;
}

function CreateContactModal<T = {}>({ onClose, isCancelSubscription }: Props<T>) {
  const { userDeleteMutate } = useUserDataMutation();
  const creatorId = localStorage.getItem('userId');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { data: userList, isLoading, isError } = useGetAllUsersQuery();
  const { chatMutate } = useChatmutations();
  const { data: chatList } = useGetAllChatByIdQuery(creatorId);

  const handleInputChange = e => {
    setSearchTerm(e.target.value);
  };

  const handleClickOutside = e => {
    if (e.target.className === 'modal-overlay') {
      onClose();
    }
  };

  const handleUserSelect = user => {
    setSelectedUser(user);
  };

  const handleDeteteUserFormSystem = async () => {
    if (!selectedUser) return;

    userDeleteMutate(
      { id: selectedUser.id },
      {
        onSuccess: () => {
          onClose();
        },
        onError: error => {
          console.error(`Failed to delete user with ID ${selectedUser.id}:`, error);
        },
      },
    );
  };

  const handleCreateChat = async () => {
    if (!selectedUser) return;

    const chatData = {
      chatName: 'Contact',
      users: [selectedUser.id, creatorId],
      type: 'private',
      creatorId,
    };

    const chatExists = chatList?.some(
      chat =>
        chat.users.length === 2 &&
        chat.users.includes(selectedUser.id.toString()) &&
        chat.users.includes(creatorId.toString()),
    );

    if (chatExists) {
      setErrorMessage('Please, check your chat list. Chat with selected user already exists.');
      return;
    }

    try {
      socket.emit('createChat', chatData);
      console.log('Chat created');
      onClose();
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  return (
    <div
      className="modal-overlay"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        fontFamily: 'Barlow',
      }}
      onClick={handleClickOutside}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '12px',
          width: '350px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
        }}
        onClick={e => e.stopPropagation()}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px', fontFamily: Barlow.semibold.fontFamily }}>
          {!isCancelSubscription ? 'Contacts' : 'Remove User from Chat'}
        </h2>

        <label style={{ display: 'block', marginBottom: '8px', fontWeight: '600', fontSize: '14px', color: '#555' }}>
          Search Users
        </label>
        <div style={{ position: 'relative' }}>
          <TextField
            placeholder="Search for users"
            variant="outlined"
            fullWidth
            onChange={handleInputChange}
            InputProps={{
              style: {
                backgroundColor: '#fafafa',
                borderRadius: '8px',
                border: '1px solid #e0e0e0',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              },
            }}
            InputLabelProps={{
              style: {
                fontWeight: '500',
                fontSize: '12px',
                color: '#888',
              },
            }}
            style={{ borderRadius: '8px' }}
          />
          <IconButton
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#e0e0e0',
              padding: '10px',
              borderRadius: '50%',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}>
            <SearchIcon />
          </IconButton>
        </div>

        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <ul style={{ listStyleType: 'none', padding: '0' }}>
            {isLoading && (
              <li style={{ textAlign: 'center', padding: '10px' }}>
                <CircularProgress />
              </li>
            )}
            {isError && (
              <li style={{ textAlign: 'center', padding: '10px', color: 'red' }}>
                Error loading users. Please try again.
              </li>
            )}
            {userList
              ?.filter(user => user.username.toLowerCase().includes(searchTerm.toLowerCase()) && user.id !== +creatorId)
              .map(user => (
                <li
                  key={user.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #eee',
                    backgroundColor: selectedUser?.id === user.id ? '#DEE9F2' : 'transparent',
                  }}
                  onClick={() => handleUserSelect(user)}>
                  <Avatar alt={user.username} src={user.photo || iconContact} style={{ marginRight: '10px' }} />
                  <div>
                    <p style={{ margin: 0 }}>{user.username}</p>
                    <span
                      style={{
                        fontSize: '12px',
                        color: user.status === 'online' ? 'green' : 'gray',
                      }}>
                      {user.status}
                    </span>
                  </div>
                </li>
              ))}
          </ul>
        </div>

        {errorMessage && (
          <p style={{ color: 'red', textAlign: 'center', fontSize: '12px', marginTop: '10px' }}>{errorMessage}</p>
        )}

        <button
          onClick={!isCancelSubscription ? handleCreateChat : handleDeteteUserFormSystem}
          style={{
            backgroundColor: 'rgb(10, 123, 192)',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            width: '100%',
            padding: '12px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '16px',
            marginTop: '10px',
          }}>
          {!isCancelSubscription ? 'Create Chat' : 'Cancel Subscription'}
        </button>
        <button
          onClick={onClose}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: '#888',
            cursor: 'pointer',
            marginTop: '10px',
            display: 'block',
            width: '100%',
            textAlign: 'center',
          }}>
          Close
        </button>
      </div>
    </div>
  );
}

export default CreateContactModal;
