import { useLinkTo } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Alert, Image, SectionList, View } from 'react-native';
import { ListItem } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import tick from '../../assets/images/iconTickBlue@3x.png';
import { PrimaryButton } from '../../components/Button';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { ChooseCalendarScreenProps } from '../../navigators/Types';
import { Calendar, updateSelectedCalendar } from '../../redux/Calendar';
import { calendarStateSelector, userStateSelector } from '../../redux/Store';
import { auth } from '../../services/Firebase';
import Style, { Colour } from '../../styles/style';
import LoginBase from '../auth/LoginBase';
import { useGetUserByIdQuery } from '../../api/queries/useGetUserByIdQuery';
import { queryClient } from '../../api/queryClient';
import { GET_EVENT_BY_CALENDAR, GET_USER_BY_ID } from '../../constants/queryKeys';
import { useGetEventByCalendarQuery, useGetExistingCalendarQuery } from '../../api/queries';
import { useCalendarMutations } from '../../api/mutations/useCalendarMutation';
import { CircularProgress, Box } from '@mui/material';
import config from '../../config.json';
import { useGetUserByFirebaseIdQuery } from '../../api/queries/useGetUserByFirebaseIdQuery';
import { useUserDataMutation } from '../../api/mutations';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const ChooseCalendarScreen = ({ route, navigation }: ChooseCalendarScreenProps) => {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  const calendarState = useSelector(calendarStateSelector);
  const userState = useSelector(userStateSelector);
  const [calendarList, setCalendarList] = useState<Calendar[]>([]);
  const [selected, setSelected] = useState<Calendar | undefined>(calendarState.selectedCalendar);
  const firebaseId = auth.currentUser?.uid;

  const [loadingError, setLoadingError] = useState<{ message: string; retry: () => {} }>();
  const [showNotReadyMessage, setShowNotReadyMessage] = useState(false);
  const [busy, setBusy] = useState(false);
  const [runOnce, setRunOnce] = useState(false);
  const safeBottomPadding = useSafeBottomPadding();
  const [idUserLocal, setIdUserLocal] = useState<any>();

  const nylasCode = route?.params?.code;
  const grantId = localStorage.getItem('grantId');
  const calendarId = localStorage.getItem('calendarId');

  const { userDataMutate } = useUserDataMutation();
  const { data: userdata } = useGetUserByIdQuery(+idUserLocal, idUserLocal);
  const { data: listExistingCalendar } = useGetExistingCalendarQuery(grantId, grantId);
  const { data: eventCalendarData, error } = useGetEventByCalendarQuery(calendarId, grantId);
  const { data: userDataFirebaseId } = useGetUserByFirebaseIdQuery(firebaseId);
  const { deleteCalendarConnectNylas } = useCalendarMutations();

  const authorise = () => {
    setBusy(true);
    const nylasPayload = {
      redirectUrl: `${config.domain}/choosecalendar`,
      responseType: 'code',
      accessType: 'online',
      state: 'sQ6vFQN',
    };
    const authUrl = `https://api.us.nylas.com/v3/connect/auth?client_id=${config.NylasIdClient}&redirect_uri=${nylasPayload.redirectUrl}&response_type=${nylasPayload.responseType}&access_type=${nylasPayload.accessType}&state=${nylasPayload.state}`;
    window.location.replace(authUrl);
  };

  const handleSubmit = async () => {
    if (selected) {
      setBusy(true);
      dispatch(updateSelectedCalendar(selected));

      if (!calendarId || !grantId) {
        Alert.alert('Missing Data', 'Please check your settings.');
        setBusy(false);
        return;
      }

      if (error) {
        Alert.alert('Error', 'An error occurred while fetching calendar events.');
        console.error('Error fetching calendar events:', error.message);
      } else if (!eventCalendarData) {
        Alert.alert('Error', 'Failed to retrieve events. Please try again later.');
      } else {
        linkTo({
          screen: 'CreateListing',
          params: {
            id: userState.listing?.id,
            skipToStep: 3,
          },
        });
      }
      setBusy(false);
    }
  };

  // const handleSubmitOld = async () => {
  //   if (selected) {
  //     setBusy(true);
  //     GetListing(userState.listing?.id!)
  //       .then(listing => {
  //         return dispatch(setListing(listing));
  //       })
  //       .then(() => {
  //         dispatch(updateSelectedCalendar(selected))
  //           .then(() => {
  //             logAnalyticsEvent('calendar_connected', {});

  //             deleteMobileCalendar();

  //             CallFunction('syncCalendar', {}).then(events => {
  //               if (!events?.length) {
  //                 logAnalyticsEvent('calendar_synced_with_no_events', {
  //                   listingId: userState.listing?.id!,
  //                   calendarId: selected.id,
  //                 });
  //               }
  //             });

  //             if (route?.params?.state) {
  //               // Navigate to invite accepted
  //               linkTo({
  //                 screen: 'AcceptInvitation',
  //                 // params: { ...invite, allDone: true },
  //               });
  //             } else if (navigation.canGoBack()) {
  //               navigation.goBack();
  //             } else {
  //               linkTo({
  //                 screen: 'CreateListing',
  //                 params: {
  //                   id: userState.listing?.id,
  //                   skipToStep: 3,
  //                 },
  //               });
  //             }
  //           })
  //           .catch((error: Error) => {
  //             captureException(error);
  //           });
  //       });
  //   } else {
  //     Alert.alert('Please select a calendar');
  //   }
  //   setBusy(false);
  // };

  const connectNylasAccount = async () => {
    try {
      if (nylasCode && !runOnce && idUserLocal) {
        setRunOnce(true);
        setBusy(true);

        const response = await fetch('https://api.us.nylas.com/v3/connect/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${config.NylasApiKey}`,
          },
          body: JSON.stringify({
            client_id: `${config.NylasIdClient}`,
            client_secret: `${config.NylasApiKey}`,
            grant_type: 'authorization_code',
            code: nylasCode,
            redirect_uri: `${config.domain}/choosecalendar`,
          }),
        });

        const dataConnectToken = await response.json();

        if (dataConnectToken?.error) {
          setLoadingError({ message: 'Error loading your calendars. Please try again.', retry: authorise });
          setBusy(false);
        }

        if (response.status === 200) {
          try {
            const responseCalendar = await fetch(
              `https://auslaw-chat.onrender.com/nylas/calendars?grantId=${dataConnectToken.grant_id}`,
            );

            if (!responseCalendar.ok) {
              throw new Error(`Failed to fetch calendars: ${responseCalendar.status}`);
            }

            const calendarData = await responseCalendar.json();

            getCalendars(true, dataConnectToken.grant_id, calendarData);
          } catch (error) {
            console.error('Error fetching calendars:', error.message);
          }
        }

        const uid = await auth.currentUser?.uid;

        const dataConnectCalendar = {
          firebaseId: uid,
          grantId: dataConnectToken.grant_id,
          calendarEmail: dataConnectToken.email,
          provider: dataConnectToken.provider,
        };

        userDataMutate(
          {
            id: idUserLocal,
            data: dataConnectCalendar,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: [GET_USER_BY_ID, idUserLocal] });
              // localStorage.setItem('grantId', dataConnectToken.grant_id);
            },
            onError: err => console.log(err),
          },
        );
        await sleep(5000);
        setBusy(false);
      }
    } catch (e) {
      setLoadingError({ message: 'Error connecting your calendar', retry: authorise });
      setBusy(false);
    }
  };

  useEffect(() => {
    connectNylasAccount();
  }, [nylasCode, idUserLocal]);

  const getCalendars = async (force = false, grantId, dataCalendar) => {
    try {
      if (force || (!calendarList.length && grantId && dataCalendar)) {
        setBusy(true);

        const calendars = dataCalendar?.map(item => ({
          title: item.name,
          id: item.id,
          timezone: item.timezone,
          accountId: item.grantId,
        }));

        if (!calendars) {
          setShowNotReadyMessage(true);
          setLoadingError(null);
          setBusy(false);
        } else {
          setCalendarList(calendars);
          setShowNotReadyMessage(false);
          setLoadingError(null);
          setBusy(false);
        }
      }
    } catch (e) {
      setLoadingError({ message: 'Error loading your calendars. Please try again.', retry: getCalendars });
      setBusy(false);
    }
  };

  useEffect(() => {
    if (listExistingCalendar && grantId) {
      getCalendars(true, grantId, listExistingCalendar);
    }
  }, [listExistingCalendar, grantId]);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setIdUserLocal(parseInt(storedUserId, 10));
    }
  }, []);

  const deleteConnectCalendar = () => {
    setBusy(true);
    const deleteCalendarParams = {
      grantId: grantId as any,
    };

    const dataConnectCalendar = {
      grantId: '',
      calendarId: '',
    };
    deleteCalendarConnectNylas(deleteCalendarParams, {
      onSuccess: data => {
        setBusy(false);
        authorise();
      },

      onError: err => {
        console.log(err);
      },
    });

    userDataMutate(
      {
        id: idUserLocal,
        data: dataConnectCalendar,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [GET_USER_BY_ID, idUserLocal] });
          localStorage.removeItem('grantId');
          localStorage.removeItem('calendarId');
        },
        onError: err => console.log(err),
      },
    );
  };

  useEffect(() => {
    setBusy(true);

    const timer = setTimeout(() => {
      setBusy(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (userdata?.grantId && userdata?.calendarId) {
      localStorage.setItem('grantId', userdata.grantId);
      localStorage.setItem('calendarId', userdata?.calendarId);
    }
  }, [userdata, selected]);

  useEffect(() => {
    if (userDataFirebaseId) {
      localStorage.setItem('userId', userDataFirebaseId.id);
    }
  }, [userDataFirebaseId]);

  return (
    <LoginBase>
      {busy && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress color="primary" />
        </Box>
      )}
      <View style={[Style.footer, { backgroundColor: 'rgba(0, 0, 0, 0)', justifyContent: 'center' }]}>
        <View style={{ backgroundColor: 'white', borderRadius: 24, padding: 30 }}>
          {loadingError && (
            <>
              <Text style={[Style.errorText, { textAlign: 'center' }]}>{loadingError?.message}</Text>
              <PrimaryButton
                loading={busy}
                containerStyle={{ marginBottom: safeBottomPadding }}
                buttonStyle={{ marginHorizontal: 20 }}
                title="Try again"
                disabled={busy}
                onPress={() => {
                  loadingError?.retry();
                }}
              />
            </>
          )}

          {showNotReadyMessage && (
            <>
              <Text style={[Style.text, { textAlign: 'center' }]}>
                We're just getting your calendars ready. We'll send you an email when they are ready.
              </Text>
              <PrimaryButton
                loading={busy}
                containerStyle={{ marginBottom: safeBottomPadding }}
                buttonStyle={{ marginHorizontal: 20 }}
                title="CONFIRM"
                disabled={busy}
                onPress={() => {
                  linkTo({
                    screen: 'CreateListing',
                    params: {
                      id: userState.listing?.id,
                      skipToStep: 3,
                    },
                  });
                }}
              />
            </>
          )}
          {!userState.listing?.nylasConnected && !calendarList?.length && !loadingError && (
            <>
              <Text style={{ textAlign: 'center', marginBottom: 10 }} fontFamily={'Barlow-Medium'}>
                Please connect your work email/calendar account
              </Text>
              <PrimaryButton
                loading={busy}
                title={!!userState.listing?.nylasConnected ? 'Connected' : 'Connect'}
                disabled={!!userState.listing?.nylasConnected}
                containerStyle={{ marginVertical: 0, borderRadius: 0 }}
                buttonStyle={{ borderRadius: 0 }}
                onPress={() => authorise()}
              />
            </>
          )}
          {(userState.listing?.nylasConnected || !!calendarList?.length) && !loadingError && !showNotReadyMessage && (
            <>
              <SectionList
                contentContainerStyle={{ flexGrow: 1 }}
                sections={[{ title: 'Calendars', data: calendarList }]}
                renderSectionHeader={({ section }) => (
                  <View>
                    <Text
                      fontFamily="Barlow-Medium"
                      style={{
                        backgroundColor: 'white',
                        color: Colour.DarkBlue,
                        letterSpacing: 1,
                        fontSize: 24,
                        padding: 15,
                      }}>
                      {section.title}
                    </Text>
                  </View>
                )}
                renderItem={({ item }) => (
                  <ListItem
                    style={{ marginLeft: 20 }}
                    bottomDivider
                    onPress={() => {
                      const dataConnectCalendar = {
                        calendarId: item.id,
                        timezone: item.timezone,
                      };
                      localStorage.setItem('calendarId', item.id);
                      localStorage.setItem('grantId', item.accountId);
                      userDataMutate(
                        {
                          id: idUserLocal,
                          data: dataConnectCalendar,
                        },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries({ queryKey: [GET_USER_BY_ID, idUserLocal] });
                            queryClient.invalidateQueries({
                              queryKey: [GET_EVENT_BY_CALENDAR, dataConnectCalendar.calendarId],
                            });
                          },
                          onError: err => console.log(err),
                        },
                      );
                      setSelected(item);
                    }}
                    hasTVPreferredFocus={undefined}
                    tvParallaxProperties={undefined}>
                    <View
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: Colour.Grey,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      {item.id === selected?.id && (
                        <Image source={tick} width={51} height={48} style={{ width: 21.5, height: 20 }} />
                      )}
                    </View>
                    <ListItem.Content>
                      <ListItem.Title>
                        <Text
                          fontFamily="Barlow-Medium"
                          style={{ fontSize: 18, color: Colour.DarkGrey, letterSpacing: 0.9 }}>
                          {item.title}
                        </Text>
                      </ListItem.Title>
                    </ListItem.Content>
                  </ListItem>
                )}
                ListEmptyComponent={
                  <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Text>No calendars are available.</Text>
                  </View>
                }
              />
              <PrimaryButton
                loading={busy}
                containerStyle={{ marginBottom: safeBottomPadding, width: 'auto' }}
                buttonStyle={{ marginHorizontal: 20, marginTop: 20 }}
                title="CHOOSE DIFFERENT ACCOUNT"
                disabled={!selected}
                onPress={() => {
                  deleteConnectCalendar();
                }}
              />
              <PrimaryButton
                loading={busy}
                containerStyle={{ marginBottom: safeBottomPadding }}
                buttonStyle={{ marginHorizontal: 20 }}
                title="CONFIRM"
                disabled={!selected}
                onPress={handleSubmit}
              />
            </>
          )}
        </View>
      </View>
    </LoginBase>
  );
};

export default ChooseCalendarScreen;
