import React, { useEffect, useState } from 'react';
import Style, { Barlow, Colour } from '../../styles/style';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import CreateChatModal from '../../components/Modals/ChatModal';
import CreateContactModal from '../../components/Modals/ContactsModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Tabs, Tab, useMediaQuery, Avatar } from '@mui/material';
import { useGetAllChatByIdQuery, useGetAllUsersQuery } from '../../api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { GET_ALL_CHATS_ID } from '../../constants/queryKeys';
import { useChatmutations, useUserDataMutation } from '../../api/mutations';
import iconContact from '../../assets/images/icon-contact.png';
import socket from '../../api/socket/socket';
import groupAvatar from '../../assets/images/auslaw_groupchat_logo.jpg';
import logoChatCommunity from '../../assets/images/logo-chat.png';
import { useLinkTo } from '@react-navigation/native';

interface ChatSideBarProps {
  userId: number;
  onChatClick: (id: number) => void;
  isVisible: boolean;
  toggleSidebar: () => void;
}

function ChatSideBar({ userId, onChatClick, isVisible, toggleSidebar }: ChatSideBarProps) {
  const linkTo = useLinkTo();
  const queryClient = useQueryClient();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isChatModalVisible, setIsChatModalVisible] = useState(false);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingChatId, setEditingChatId] = useState<number | null>(null);
  const [editedChatName, setEditedChatName] = useState<string>('');
  const [activeChatId, setActiveChatId] = useState<number | null>(null);
  const [isEditChats, setIsEditChats] = useState(false);
  const [chatId, setChatId] = useState();
  const [unreadCounts, setUnreadCounts] = useState<{ [key: number]: number }>({});
  const [tabIndex, setTabIndex] = useState(0);
  const { data: userList } = useGetAllUsersQuery();
  const isMobile = useMediaQuery('(max-width:900px)');
  const [chats, setChats] = useState<Chat[]>([]);
  const [isCancelSubscription, setIsCancelSubscription] = useState(false);

  const checkRoleUser = (id: number) => {
    const user = userList && userList.find(user => user.id === userId);

    return user?.role === 'admin' || user?.role === 'owner';
  };

  const user = userList && userList.find(user => user.id === userId);

  const isAdmin = checkRoleUser(userId);

  const openEditChatsModal = (id: number | React.SetStateAction<undefined>) => {
    setIsEditChats(true);
    setIsChatModalVisible(true);
    setChatId(id);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const { chatEditMutate } = useChatmutations();

  queryClient.invalidateQueries({ queryKey: [GET_ALL_CHATS_ID, userId] });
  const { data: chatList, isLoading, error } = useGetAllChatByIdQuery(userId);

  useEffect(() => {
    if (chatList?.length) {
      const sortedChats = [...chatList].sort((a: Chat, b: Chat) => {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      });
      setChats(sortedChats);
    }
  }, [chatList]);

  const closeContactModal = () => setIsContactModalVisible(false);

  const getInitials = (name: string) => {
    const parts = name?.split(' ');
    const initials = parts?.map(part => part.charAt(0).toUpperCase()).join('');

    return initials;
  };

  const toggleMenu = () => setIsMenuVisible(prev => !prev);

  const closeMenu = () => setIsMenuVisible(false);

  const openChatModal = () => {
    setIsChatModalVisible(true);
    closeMenu();
  };

  const closeChatModal = () => {
    setIsChatModalVisible(false);
    setIsEditChats(false);
  };

  const openContactModal = () => {
    setIsContactModalVisible(true);
    closeMenu();
  };

  const handleSaveEdit = async (chatId: number) => {
    try {
      chatEditMutate({
        id: chatId,
        data: { chatName: editedChatName },
      });
    } catch (error) {
      console.error('Error updating chat:', error);
    }
  };

  const handleChatClick = (chatId: number) => {
    setActiveChatId(chatId);
    onChatClick(chatId);
  };

  const filteredChats = (Array.isArray(chats) ? chats : []).filter(chat => {
    if (chat.chatName === 'Contact') {
      return chat.usersInfo.some(
        user => user.id.toString() !== userId && user.username.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    return chat.chatName?.toLowerCase().includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    socket.on('chatCreated', newChat => {
      newChat.users.includes(userId) ? setChats(prevChats => [...prevChats, newChat]) : console.log(newChat);
    });

    socket.on('chatEdited', editedChat => {
      setChats(prevChats => prevChats.map(chat => (chat.id === editedChat.id ? editedChat : chat)));
    });

    socket.on('chatDeleted', deletedChat => {
      const updatedChats = chats.filter(chat => chat.id !== deletedChat.id);

      setChats(updatedChats);
    });

    socket.on('unreadCount', ({ chatId, unreadCount }) => {
      setUnreadCounts(prev => ({ ...prev, [chatId]: unreadCount }));
    });

    return () => {
      socket.off('chatCreated');
      socket.off('editChat');
      socket.off('deleteChat');
    };
  }, [setChats, userId]);

  const mobStyleSidebar = {
    background: 'white',
    width: isVisible ? '100%' : '20%',
    height: 'calc(100vh - 64px)',
    padding: '10px',
    position: isVisible ? 'fixed' : 'relative',
    boxSizing: 'border-box',
    overflowY: 'auto',
    left: isVisible ? 0 : '-100%',
    transition: 'left 0.3s ease-in-out',
    zIndex: 1000,
  };

  const desktopStyleSidebar = {
    background: 'white',
    minWidth: '340px',
    height: 'calc(100vh - 64px)',
    padding: '10px',
    position: 'relative',
    boxSizing: 'border-box',
    overflowY: 'auto',
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const menuElement = document.getElementById('menuDropdown');
      if (menuElement && !menuElement.contains(event.target as Node)) {
        setIsMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (isLoading) return <div>Loading chats...</div>;
  if (error) return <div>Error loading chats</div>;

  return (
    <div style={isMobile ? mobStyleSidebar : desktopStyleSidebar}>
      <button
        style={{
          width: '100%',
          padding: '10px 20px',
          background: 'linear-gradient(to right, #56ccf2, #2f80ed)', // Новий градієнт
          color: '#fff',
          border: 'none',
          borderRadius: '25px',
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: 'bold',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Легка тінь
          transition: 'all 0.3s ease',
        }}
        onClick={() => {
          linkTo('/community');
        }}
        onMouseOver={e => {
          e.target.style.transform = 'scale(1.05)'; // Збільшення при наведенні
          e.target.style.boxShadow = '0 6px 10px rgba(0, 0, 0, 0.15)'; // Більша тінь
        }}
        onMouseOut={e => {
          e.target.style.transform = 'scale(1)';
          e.target.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
        }}>
        Access Community
      </button>

      {/* {isAdmin && (
        <button
          style={{
            marginTop: '10px',
            width: '100%',
            padding: '10px 20px',
            background: 'linear-gradient(to right, #e53935, #d32f2f)',
            color: '#fff',
            border: 'none',
            borderRadius: '25px',
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 'bold',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.3s ease',
          }}
          onClick={() => {
            setIsContactModalVisible(true);
            setIsCancelSubscription(true);
          }}
          onMouseOver={e => {
            e.target.style.transform = 'scale(1.05)';
            e.target.style.boxShadow = '0 6px 10px rgba(0, 0, 0, 0.15)';
          }}
          onMouseOut={e => {
            e.target.style.transform = 'scale(1)';
            e.target.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
          }}>
          Cancel User Subscription
        </button>
      )} */}

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0' }}>
        <ControlPointIcon style={{ fontSize: 20, color: Colour.Blue, cursor: 'pointer' }} onClick={toggleMenu} />

        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', marginLeft: '10px' }}>
          <SearchIcon style={{ position: 'absolute', left: '10px', fontSize: 18, color: '#888' }} />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              padding: '8px 35px',
              borderRadius: '20px',
              border: '1px solid #ccc',
              backgroundColor: '#f1f3f4',
              outline: 'none',
              color: '#333',
            }}
          />
        </div>
      </div>

      {isMenuVisible && (
        <div
          id="menuDropdown"
          style={{
            position: 'absolute',
            top: '100px',
            left: '10px',
            backgroundColor: 'white',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
            borderRadius: '12px',
            zIndex: 1000,
            padding: '20px',
            width: '87%',
            animation: 'slideDown 0.3s ease-in-out',
            transition: 'all 0.3s ease',
          }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
            {user?.photo ? (
              <img
                src={user?.photo}
                alt="User Avatar"
                style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  marginRight: '10px',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Avatar alt={'Avatar'} src={iconContact} sx={{ width: 50, height: 50 }} />
            )}
            <span
              style={{
                paddingTop: '10px',
                fontSize: '15px',
                fontFamily: Barlow.semibold.fontFamily,
                fontWeight: '500',
              }}>
              {user?.username}
            </span>
          </div>

          <button
            onClick={openContactModal}
            style={{
              backgroundColor: Colour.Blue,
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              width: '100%',
              padding: '12px 15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '16px',
              fontFamily: Barlow.semibold.fontFamily,
              marginBottom: '15px',
              transition: 'background-color 0.2s ease',
            }}>
            <PersonIcon style={{ fontSize: 25, color: 'white', marginRight: '8px' }} />
            Find Contact
          </button>
          <button
            onClick={openChatModal}
            style={{
              backgroundColor: Colour.Blue,
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              width: '100%',
              padding: '12px 15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '16px',
              fontFamily: Barlow.semibold.fontFamily,
              transition: 'background-color 0.2s ease',
            }}>
            <ChatIcon style={{ fontSize: 25, color: 'white', marginRight: '8px' }} />
            Create Chat Group
          </button>
        </div>
      )}

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {filteredChats.length > 0 ? (
          filteredChats.map(chat => (
            <div
              key={chat.id}
              style={{
                border: chat.type === 'public' ? '1px solid #0A7BB8' : '1px solid  #ccc',
                width: isVisible ? '100%' : '100%',
                height: '70px',
                borderRadius: 8,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: activeChatId === chat.id ? 'Colour.LightBlue' : 'white',
              }}
              onClick={() => {
                handleChatClick(chat.id);
                toggleSidebar();
              }}>
              {chat.type === 'private' ? (
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    background: Colour.Blue,
                    borderRadius: '50%',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    color: 'white',
                    fontFamily: Barlow.semibold.fontFamily,
                    marginLeft: 10,
                    marginRight: 5,
                  }}>
                  {chat.chatName === 'Contact' ? (
                    <Avatar
                      alt={'Avatar'}
                      src={chat.usersInfo.find(userInfo => userInfo.id !== userId)?.photo || iconContact}
                      sx={{ width: 50, height: 50 }}>
                      {getInitials(chat.usersInfo.find(userInfo => userInfo.id !== userId)?.username)}
                    </Avatar>
                  ) : (
                    <Avatar alt={'Avatar'} src={groupAvatar} sx={{ width: 50, height: 50 }}>
                      {getInitials(chat.usersInfo.find(userInfo => userInfo.id !== userId)?.username)}
                    </Avatar>
                  )}
                </div>
              ) : (
                <img src={logoChatCommunity} style={{ width: '50px', height: '50px', display: 'block', margin: 10 }} />
              )}

              <div>
                <p
                  style={{
                    color: Colour.DarkGrey,
                    fontSize: 15,
                    fontFamily: 'Barlow',
                    display: 'block',
                    margin: 0,
                    fontWeight: 500,
                  }}>
                  {chat.chatName === 'Contact'
                    ? chat.usersInfo.find(userInfo => userInfo.id !== userId)?.username
                    : chat.chatName}
                </p>
                {chat.type === 'public' && (
                  <p
                    style={{
                      color: Colour.DarkGrey,
                      fontSize: 10,
                      fontFamily: 'Barlow',
                      display: 'block',
                      margin: 0,
                      paddingTop: 5,
                    }}>
                    {chat.users.length} members
                  </p>
                )}

                {unreadCounts[chat.id] > 0 && (
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      backgroundColor: Colour.Blue,
                      borderRadius: '50%',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      marginLeft: '8px',
                    }}>
                    {unreadCounts[chat.id]}
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>No chats found.</div>
        )}
      </div>

      {isChatModalVisible && (
        <CreateChatModal
          userId={userId}
          edit={isEditChats}
          chatId={chatId}
          onChatClick={onChatClick}
          onClose={() => {
            closeChatModal();
            queryClient.invalidateQueries({ queryKey: [GET_ALL_CHATS_ID, userId] });
          }}
        />
      )}
      {isContactModalVisible && (
        <CreateContactModal
          isCancelSubscription={isCancelSubscription}
          onClose={() => {
            setIsCancelSubscription(false);
            closeContactModal();
            queryClient.invalidateQueries({ queryKey: [GET_ALL_CHATS_ID, userId] });
          }}
        />
      )}

      <style>
        {`
          @keyframes slideDown {
            from {
              transform: translateY(-20px);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
}

export default ChatSideBar;
