import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_USER_BY_ID } from '../../constants/queryKeys';

export const useGetUserByIdQuery = (idUser: number, isData:any) => {
  return useQuery({
    queryKey: [GET_USER_BY_ID, idUser],
    queryFn: () => apiHandlers.users.getUserById(idUser).then(data => data.data),
    enabled: !!isData
  });
};
