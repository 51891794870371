import { useMutation } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';

export const useUserDataMutation = () => {
  const { mutate: userDataMutate } = useMutation({
    mutationFn: apiHandlers.users.updateUserData,
  });

  const { mutate: userDeleteMutate } = useMutation({
    mutationFn: apiHandlers.users.deleteUserData,
  });

  return {
    userDataMutate,
    userDeleteMutate,
  };
};
