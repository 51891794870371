import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { Icon, Input } from 'react-native-elements';
import { Asset } from 'react-native-image-picker';
import { PrimaryButton } from '../../../components/Button';
import ModalChooseAvatar from '../../../components/ModalChooseAvatar';
import Text from '../../../components/Text';
import { Listing } from '../../../redux/User';
import Style, { Colour } from '../../../styles/style';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IListingParams } from '../utils/model';
import { useUserDataMutation } from '../../../api/mutations/useUserDataMutation';

const stylesheet = StyleSheet.create({
  optionalModifier: { color: Colour.DarkGrey, fontSize: 16 },
  dollar: { fontSize: 18, color: '#757575', fontFamily: 'Barlow', fontWeight: '500' },
});

interface Props {
  listing?: Listing;
  image?: Asset;
  setImage(val: Asset): void;
  onNextStep2(values: IListingParams): void;
}

const CreateListingStep1 = (props: Props) => {
  const { listing, image, setImage, onNextStep2 } = props;

  const { control, handleSubmit, setValue } = useForm<IListingParams>({
    defaultValues: {
      name: listing?.name || '',
      hourlyRate: listing?.hourlyRate,
      dailyRate: listing?.dailyRate,
      reportCost: listing?.reportCost,
      bio: listing?.bio || '',
    },
  });

  useEffect(() => {
    setValue('name', listing?.name || '');
    setValue('hourlyRate', listing?.hourlyRate);
    setValue('dailyRate', listing?.dailyRate);
    setValue('reportCost', listing?.reportCost);
    setValue('bio', listing?.bio || '');
  }, [listing]);

  return (
    <>
      <View style={{ flex: 1 }}>
        <ModalChooseAvatar style={{ marginTop: 20 }} image={image} imageUrl={listing?.imageUrl} onSetImage={setImage} />

        <Text style={Style.label}>Full name</Text>
        <Controller
          control={control}
          name="name"
          rules={{
            required: { value: true, message: 'Name is required' },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              testID="nameInput"
              onChangeText={onChange}
              errorMessage={error?.message}
              placeholder="e.g. John Smith"
            />
          )}
        />

        <Text style={Style.label}>
          Hourly rate&nbsp;
          <Text fontFamily="Barlow-Italic" style={stylesheet.optionalModifier}>
            (ex GST) Optional
          </Text>
        </Text>

        <Controller
          control={control}
          name="hourlyRate"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value?.toString() || ''}
              onChangeText={onChange}
              keyboardType="number-pad"
              errorMessage={error?.message}
              leftIcon={<Text style={stylesheet.dollar}>$</Text>}
            />
          )}
        />

        <Text style={Style.label}>
          Daily rate&nbsp;
          <Text fontFamily="Barlow-Italic" style={stylesheet.optionalModifier}>
            (ex GST) Optional
          </Text>
        </Text>
        <Controller
          control={control}
          name="dailyRate"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value?.toString() || ''}
              onChangeText={onChange}
              keyboardType="number-pad"
              errorMessage={error?.message}
              leftIcon={<Text style={stylesheet.dollar}>$</Text>}
            />
          )}
        />

        <Text style={Style.label}>
          Report cost&nbsp;
          <Text fontFamily="Barlow-Italic" style={stylesheet.optionalModifier}>
            (ex GST) Optional
          </Text>
        </Text>
        <Controller
          control={control}
          name="reportCost"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value?.toString() || ''}
              onChangeText={onChange}
              keyboardType="number-pad"
              errorMessage={error?.message}
              leftIcon={<Text style={stylesheet.dollar}>$</Text>}
            />
          )}
        />

        <Text style={Style.label}>Bio</Text>
        <View>
          <Controller
            control={control}
            name="bio"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                value={value}
                onChangeText={onChange}
                errorMessage={error?.message}
                multiline
                numberOfLines={4}
                inputStyle={{ fontFamily: 'Barlow', textAlignVertical: 'top', minHeight: 100 }}
              />
            )}
          />
        </View>
      </View>

      <PrimaryButton
        title="NEXT"
        iconRight
        icon={
          <View style={{ justifyContent: 'center' }}>
            <KeyboardArrowRightIcon sx={{ color: 'white' }} />
          </View>
        }
        onPress={handleSubmit(onNextStep2)}
      />
    </>
  );
};

export default CreateListingStep1;
