import React, { useEffect, useState } from 'react';
import imgPrivateChat from '../../assets/images/chatPage1.png';
import imgCommunity from '../../assets/images/comynityPage1.png';
import { PrimaryButton } from '../../components/Button';
import { useLinkTo } from '@react-navigation/native';
import { useMediaQuery } from '@mui/material';
import { useGetAllCommunitiesQuery } from '../../api/queries';

function ChooseCommunity() {
  const linkTo = useLinkTo();
  const isMobile = useMediaQuery('(max-width:900px)');

  const { data: communityList } = useGetAllCommunitiesQuery();
  const storedChatId = localStorage.getItem('idChat');

  useEffect(() => {
    if (communityList && communityList.length > 0) {
      localStorage.setItem('idChat', JSON.stringify(communityList[0].id));
    }
  }, [communityList]);

  return (
    <div>
      <h1
        style={{
          display: 'block',
          color: 'white',
          fontSize: isMobile ? '24px' : '32px',
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          marginBottom: '50px',
          fontFamily: 'Barlow',
        }}>
        AusLaw Social
      </h1>

      <div
        style={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          fontFamily: 'Barlow',
          justifyContent: 'flex-start',
          alignItems: 'center',
          position: 'relative',
        }}>
        <div
          style={{
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '15px',
            padding: isMobile ? '20px 0' : '0',
            height: '70%',
            justifyContent: 'space-between',
          }}>
          <img src={imgPrivateChat} style={{ width: '70%', height: 'auto', paddingBottom: '30px' }} />

          <p
            style={{
              color: 'white',
              fontSize: isMobile ? 16 : 18,
              width: isMobile ? '80%' : '70%',
              textAlign: 'center',
              marginTop: isMobile ? 10 : 20,
              minHeight: '80px',
            }}>
            Create private one-on-one chats or join group chats to connect, collaborate, and share updates with others.
          </p>

          <PrimaryButton
            containerStyle={{
              marginTop: 10,
              paddingHorizontal: isMobile ? 20 : 30,
              width: isMobile ? '60%' : '40%',
            }}
            title="Join Chat"
            onPress={() => linkTo('/chat')}
            inverted
          />
        </div>

        <div
          style={{
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '15px',
            padding: isMobile ? '20px 0' : '0',
            height: '70%',
            justifyContent: 'space-between',
          }}>
          <img src={imgCommunity} style={{ width: '70%', height: 'auto', paddingBottom: '30px' }} />

          <p
            style={{
              color: 'white',
              fontSize: isMobile ? 16 : 18,
              width: isMobile ? '80%' : '70%',
              textAlign: 'center',
              height: isMobile ? '120px' : '80px',
            }}>
            Join different communities where the latest news and updates from all professions can be discussed. These
            are spaces for sharing information, finding work, and staying up to date with events around Australia.
          </p>

          <PrimaryButton
            containerStyle={{
              marginTop: 10,
              paddingHorizontal: isMobile ? 20 : 30,
              width: isMobile ? '60%' : '40%',
            }}
            title="Join Community"
            onPress={() => linkTo(`/community?id=${storedChatId}`)}
            inverted
          />
        </div>
      </div>
    </div>
  );
}

export default ChooseCommunity;
