import React, { useEffect, useState } from 'react';
import {
  TextField,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Avatar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useGetAllChatByIdQuery, useGetAllUsersQuery } from '../../api/queries';
import { useChatmutations } from '../../api/mutations';
import { Socket, io } from 'socket.io-client';
import { Barlow } from '../../styles/style';
import socket from '../../api/socket/socket';
import iconContact from '../../assets/images/icon-contact.png';

function CreateChatModal({ onClose, edit, userId, chatId, onChatClick }: any) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [chatName, setChatName] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isPrivate, setIsPrivate] = useState(true);
  const { data: userList, isLoading, isError } = useGetAllUsersQuery();
  const [errorNmae, setErrorName] = useState('');
  const [errorSelectUser, setErrorSelectUser] = useState('');
  const { chatMutate } = useChatmutations();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isDelete, setIsDelete] = useState(false);

  const checkRoleUser = (id: number) => {
    const user = userList && userList.find(user => user.id === id);
    return user?.role === 'admin';
  };

  const isAdmin = checkRoleUser(userId);

  const { data: chatList, error } = useGetAllChatByIdQuery(userId);

  const filterCurrentChat = chatList?.filter(item => item.id === chatId);
  const isContact = filterCurrentChat?.map(chat => chat.chatName === 'Contact')[0];
  const isCreator = filterCurrentChat?.map(chat => chat.creatorId === String(userId))[0];
  const users = userList?.filter(user => filterCurrentChat[0]?.users.includes(String(user.id)));

  console.log(isCreator, '2321');

  const handleInputChange = e => {
    setSearchTerm(e.target.value);
  };

  const handleUserSelect = user => {
    if (!selectedUsers.includes(user.id)) {
      setSelectedUsers([...selectedUsers, user.id]);
    }
  };

  const handleUserRemove = user => {
    setSelectedUsers(selectedUsers.filter(selected => selected !== user));
  };

  const handleCreate = async () => {
    const creatorId = localStorage.getItem('userId');

    if (!chatName.trim()) {
      setErrorName('Please enter a chat name');
      return;
    }

    if (selectedUsers.length < 2 && isPrivate && !edit) {
      setErrorSelectUser('Please select at least two users');
      return;
    }

    const chatData = {
      chatName,
      users: [...selectedUsers.map(String), creatorId],
      type: isPrivate ? 'private' : 'public',
      creatorId: userId,
    };

    const chatDataEdit = {
      chatId,
      updateChatDto: {
        ...chatData,
      },
    };

    try {
      if (!edit) {
        socket.emit('createChat', chatData);
      } else {
        socket.emit('editChat', chatDataEdit);
      }

      onClose();
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  const handleOverlayClick = e => {
    if (e.target.className === 'modal-overlay') {
      onClose();
    }
  };

  const handlePrivacyChange = e => {
    setIsPrivate(e.target.checked);
  };

  const handleDeleteConfirmOpen = () => {
    setOpenDeleteConfirm(true);
  };

  const handleDeleteConfirmClose = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDeleteChat = async () => {
    socket.emit('deleteChat', { chatId });
    setOpenDeleteConfirm(false);
    onChatClick(null);
    onClose();
  };

  const handleLeave = () => {
    setSelectedUsers(prevUsers => prevUsers.filter(user => user !== userId));

    const updatedChatData = {
      chatName,
      users: selectedUsers.filter(user => user !== userId),
      type: isPrivate ? 'private' : 'public',
      creatorId: userId,
    };

    const updatedChatDataEdit = {
      chatId,
      updateChatDto: updatedChatData,
    };

    socket.emit('editChat', updatedChatDataEdit);

    onClose();
  };

  const handleContactSelect = async user => {
    await setSelectedUser(user);

    if (!selectedUser) return;

    const chatData = {
      chatName: 'Contact',
      users: [selectedUser.id, userId],
      type: 'private',
      creatorId: userId,
    };

    const chatExists = chatList?.some(
      chat =>
        chat.users.length === 2 &&
        chat.users.includes(selectedUser.id.toString()) &&
        chat.users.includes(userId.toString()),
    );

    if (chatExists) {
      setErrorMessage('Please, check your chat list. Chat with selected user already exists.');

      onClose();

      return;
    }

    try {
      socket.emit('createChat', chatData);
      onClose();
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  useEffect(() => {
    if (edit && filterCurrentChat) {
      const filterSelectedUsers: number[] = filterCurrentChat[0].users.filter(item => +item !== +userId).map(Number);

      setChatName(filterCurrentChat[0].chatName || '');

      if (filterCurrentChat[0].type === 'public') {
        setIsPrivate(false);
      }

      setSelectedUsers(filterSelectedUsers);
    }
  }, [edit]);

  return (
    <div
      className="modal-overlay"
      onClick={handleOverlayClick}
      style={{
        position: 'fixed',
        top: 10,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        fontFamily: 'Barlow',
      }}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '12px',
          maxHeight: '70%',
          overflowY: 'auto',
          width: '400px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
        }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px', fontFamily: Barlow.semibold.fontFamily }}>
          {edit
            ? `${filterCurrentChat[0]?.chatName} ${
                filterCurrentChat[0]?.chatName === 'Contact' ? 'Management' : 'Members'
              }`
            : isPrivate
            ? 'Create Ghat'
            : 'Create Community'}
        </h2>

        {edit && !isCreator && !isContact && (
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <ul style={{ listStyleType: 'none', padding: '0' }}>
              {isLoading && (
                <li style={{ textAlign: 'center', padding: '10px' }}>
                  <CircularProgress />
                </li>
              )}
              {isError && (
                <li style={{ textAlign: 'center', padding: '10px', color: 'red' }}>
                  Error loading users. Please try again.
                </li>
              )}
              {users
                ?.filter(user => user.username.toLowerCase().includes(searchTerm.toLowerCase()))
                .map(user => (
                  <li
                    key={user.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px',
                      cursor: 'pointer',
                      borderBottom: '1px solid #eee',
                      backgroundColor: selectedUser?.id === user.id ? '#DEE9F2' : 'transparent',
                    }}
                    onClick={() => handleContactSelect(user)}>
                    <Avatar alt={user.username} src={user.photo || iconContact} style={{ marginRight: '10px' }} />
                    <div>
                      <p style={{ margin: 0 }}>{user.username}</p>
                      <span
                        style={{
                          fontSize: '12px',
                          color: user.status === 'online' ? 'green' : 'gray',
                        }}>
                        {user.status}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}

        {!edit || (!isContact && isCreator) ? (
          <>
            <label
              style={{
                display: 'block',
                fontFamily: 'Barlow',
                marginBottom: '8px',
                fontWeight: '600',
                fontSize: '14px',
                color: '#555',
              }}>
              Name Group
            </label>
            <TextField
              placeholder="Name Group"
              variant="outlined"
              fullWidth
              value={chatName}
              onChange={e => setChatName(e.target.value)}
              InputProps={{
                style: {
                  backgroundColor: '#fafafa',
                  borderRadius: '8px',
                  border: '1px solid #e0e0e0',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                },
              }}
              InputLabelProps={{
                style: {
                  fontWeight: '500',
                  fontSize: '12px',
                  color: '#888',
                },
              }}
              style={{
                marginBottom: '10px',
              }}
              FormHelperTextProps={{
                style: { color: '#f44336', fontSize: '12px' },
              }}
            />

            {errorNmae && !chatName && (
              <p style={{ margin: 0, fontSize: '12px', color: 'red', marginBottom: '10px' }}>{errorNmae}</p>
            )}

            <label
              style={{ display: 'block', marginBottom: '8px', fontWeight: '600', fontSize: '14px', color: '#555' }}>
              List of Users
            </label>
            <div style={{ position: 'relative', marginBottom: '16px' }}>
              <TextField
                placeholder="Search for users"
                variant="outlined"
                fullWidth
                onChange={handleInputChange}
                InputProps={{
                  style: {
                    backgroundColor: '#fafafa',
                    borderRadius: '8px',
                    border: '1px solid #e0e0e0',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#888',
                  },
                }}
                style={{ borderRadius: '8px' }}
              />
              <IconButton
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: '#e0e0e0',
                  padding: '10px',
                  borderRadius: '50%',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                }}>
                <SearchIcon />
              </IconButton>
            </div>
            {errorSelectUser && (
              <p style={{ margin: 0, fontSize: '12px', color: 'red', marginBottom: '10px' }}>{errorSelectUser}</p>
            )}
            <div style={{ maxHeight: '150px', overflowY: 'auto', marginBottom: '20px' }}>
              {isLoading && (
                <div style={{ textAlign: 'center', padding: '10px' }}>
                  <CircularProgress />
                </div>
              )}
              {isError && (
                <div style={{ textAlign: 'center', padding: '10px', color: 'red' }}>
                  Error loading users. Please try again.
                </div>
              )}
              {userList
                ?.filter(user => user.id !== +userId && user.username.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((user, index) => (
                  <div
                    key={index}
                    onClick={() => handleUserSelect(user)}
                    style={{
                      padding: '10px',
                      borderBottom: '1px solid #eee',
                      cursor: 'pointer',
                      backgroundColor: selectedUsers.includes(user.id) ? '#DEE9F2' : 'transparent',
                    }}>
                    <span>{user.username}</span>
                  </div>
                ))}
            </div>

            <div>
              {selectedUsers.length > 0 && (
                <label
                  style={{
                    fontFamily: 'Barlow',
                    marginBottom: '12px',
                    fontWeight: '600',
                    fontSize: '14px',
                    color: '#555',
                  }}>
                  Selected Users
                </label>
              )}
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                  marginTop: '10px',
                  marginBottom: '20px',
                }}>
                {selectedUsers.map((userId, index) => {
                  const user = userList && userList.find(u => u.id === userId);
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '4px 8px',
                        backgroundColor: '#DEE9F2',
                        borderRadius: '12px',
                        marginBottom: '6px',
                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                      }}>
                      <span
                        style={{
                          fontSize: '12px',
                          color: '#333',
                          fontWeight: '600',
                        }}>
                        {user ? user.username : 'Unknown User'}
                      </span>
                      <IconButton
                        size="small"
                        onClick={() => handleUserRemove(userId)}
                        style={{
                          marginLeft: '8px',
                          padding: '4px',
                          backgroundColor: 'transparent',
                          color: '#666',
                          borderRadius: '50%',
                        }}>
                        ✕
                      </IconButton>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : null}

        {!edit || (!isContact && isCreator) ? (
          <button
            onClick={handleCreate}
            style={{
              backgroundColor: 'rgb(10, 123, 192)',
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              width: '100%',
              padding: '12px 15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '16px',
              marginTop: '10px',
            }}>
            {edit ? 'Update' : 'Create'}
          </button>
        ) : null}

        {edit ? (
          isCreator || isContact ? (
            <>
              <button
                onClick={handleDeleteConfirmOpen}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: 'red',
                  cursor: 'pointer',
                  marginTop: '10px',
                  display: 'block',
                  width: '100%',
                  textAlign: 'center',
                }}>
                Delete Current Chat
              </button>
            </>
          ) : (
            <button
              onClick={handleLeave}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: 'red',
                cursor: 'pointer',
                marginTop: '10px',
                display: 'block',
                width: '100%',
                textAlign: 'center',
              }}>
              Leave from Chat
            </button>
          )
        ) : null}

        <button
          onClick={onClose}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: '#888',
            cursor: 'pointer',
            marginTop: '10px',
            display: 'block',
            width: '100%',
            textAlign: 'center',
          }}>
          Close
        </button>
      </div>
      <Dialog open={openDeleteConfirm} onClose={handleDeleteConfirmClose}>
        <DialogTitle>Are you sure you want to delete this chat?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteChat} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateChatModal;
