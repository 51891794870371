import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_ALL_EXISTING_CALENDAR } from '../../constants/queryKeys';

export const useGetExistingCalendarQuery = (idGrand: string, isData: any) => {
  return useQuery({
    queryKey: [GET_ALL_EXISTING_CALENDAR, idGrand],
    queryFn: () => apiHandlers.connectCalendar.getExistingCalendar(idGrand).then(data => data.data),
    enabled: !!isData
  });
};
