import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_USER_BY_FIREBASE_ID } from '../../constants/queryKeys';

export const useGetUserByFirebaseIdQuery = (idFirebase: string) => {
  return useQuery({
    queryKey: [GET_USER_BY_FIREBASE_ID, idFirebase],
    queryFn: () => apiHandlers.users.getUserByFirebaseId(idFirebase).then(data => data.data),
    enabled: !!idFirebase
  });
};
