import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import { ISODateString } from 'react-native-calendar-events';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../../components/Button';
import { monday, saturday, selectedTimesHumanReadable } from '../../components/Calendar/CalendarHelper';
import MonthStrip from '../../components/Calendar/MonthStrip';
import WeekStrip from '../../components/Calendar/WeekStrip';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { CalendarScreenProps } from '../../navigators/Types';
import { CalendarEvent } from '../../redux/Calendar';
import { setDateRange } from '../../redux/Search';
import { userStateSelector } from '../../redux/Store';
import { EventsForUser, getFirestoreDataAll, logAnalyticsEvent } from '../../services/Firebase';
import style, { Colour } from '../../styles/style';
import { popSafely } from '../../tools/NavHelpers';
import { useLinkTo } from '@react-navigation/native';
import LoginBase from '../auth/LoginBase';
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { useGetUserByFirebaseIdQuery } from '../../api/queries/useGetUserByFirebaseIdQuery';
import { useGetEventByCalendarQuery } from '../../api/queries';
import socket from '../../api/socket/socket';

const CalendarScreen = ({ route, navigation }: CalendarScreenProps) => {
  const linkTo = useLinkTo();
  const dispatch = useDispatch();

  const userState = useSelector(userStateSelector);

  const listing = route?.params;

  const [events, setEvents] = useState<CalendarEvent[]>();

  const [date, setDate] = useState<moment.Moment>(() => {
    const currentDate = moment().startOf('day');
    return (currentDate.day() < saturday ? currentDate : moment(currentDate).startOf('day').add(1, 'week')).isoWeekday(
      monday,
    );
  });

  const [selectedTimes, setSelectedTimes] = useState<ISODateString[]>([]);
  const [dataCarrentEvents, setDataCarrentEvents] = useState<any>([]);

  const [dataEvent, setDataEvent] = useState();

  const { data: userData } = useGetUserByFirebaseIdQuery(listing?.profileId);

  const convertToISO = timestamp => {
    if (!timestamp || isNaN(timestamp)) {
      console.error('Invalid timestamp:', timestamp);
      return null;
    }
    return new Date(timestamp * 1000).toISOString();
  };

  const calendarId = userData?.calendarId;
  const grantId = userData?.grantId;

  const { data: eventCalendarData, error, isLoading } = useGetEventByCalendarQuery(calendarId, grantId);

  useEffect(() => {
    if (Array.isArray(dataCarrentEvents)) {
      const result = dataCarrentEvents?.map(event => ({
        startDate: convertToISO(event.when.startTime ?? event.when.start_time),
        endDate: convertToISO(event.when.endTime ?? event.when.end_time),
      }));
      setDataEvent(result);
    } else {
      console.warn('eventCalendarData.data is not an array:', eventCalendarData?.data);
    }
  }, [dataCarrentEvents]);

  const userIsTheOwner = useMemo(
    () => userState.auth?.userId == listing?.profileId,
    [listing?.profileId, userState.auth?.userId],
  );

  const changeDate = (val: moment.Moment) => setDate(val);

  //Currently doesn't change if window is resized...
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const filteredEvents = useMemo(() => {
    if (dataEvent) {
      const startDate = date.toISOString();
      const endDate = moment(date).add(6, 'day').toISOString();
      const filtered = dataEvent.filter(event => event.startDate >= startDate && event.endDate <= endDate);
      return filtered;
    }
    return [];
  }, [date, dataEvent]);

  useEffect(() => {
    // const getEvents = async () => {
    //   if (listing?.profileId) {
    //     logAnalyticsEvent('calendar_viewed', { listingId: listing.id });

    //     return EventsForUser(listing.profileId!, e => setEvents(e ?? []));
    //   }
    // };
    // getEvents();
    if (filteredEvents) {
      setEvents(filteredEvents);
    } else {
      setEvents([]);
    }
  }, [filteredEvents]);

  useEffect(() => {
    if (eventCalendarData) {
      setDataCarrentEvents(eventCalendarData.data);
    }
  }, [eventCalendarData]);

  const handleEventCreated = eventData => {
    if (eventData.object.grant_id === grantId) {
      setDataCarrentEvents(prevEvents => [...prevEvents, eventData.object]);
    }
  };
  const handleEventUpdated = eventData => {
    if (eventData.object.grant_id === grantId) {
      setDataCarrentEvents(prevEvents =>
        prevEvents.map(event => (event.id === eventData.object.id ? { ...event, ...eventData.object } : event)),
      );
    }
  };
  const handleEventDeleted = eventData => {
    if (eventData.object.grant_id === grantId) {
      setDataCarrentEvents(prevEvents => prevEvents.filter(event => event.id !== eventData.object.id));
    }
  };

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }

    socket.on('calendarEventCreated', eventData => {
      handleEventCreated(eventData);
    });

    socket.on('calendarEventUpdated', event => handleEventUpdated(event));
    socket.on('calendarEventDeleted', event => handleEventDeleted(event));

    return () => {
      socket.off('calendarEventCreated', handleEventCreated);
      socket.off('calendarEventUpdated', handleEventUpdated);
      socket.off('calendarEventDeleted', handleEventDeleted);
    };
  }, []);

  return (
    <LoginBase>
      <View
        onClick={() => history.back()}
        style={{
          display: 'flex',
          cursor: 'pointer',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100vw',
          gap: '5px',
          marginLeft: '20px',
        }}>
        <KeyboardBackspaceTwoToneIcon sx={{ color: Colour.White }} /> <Text style={{ color: 'white' }}>BACK</Text>
      </View>
      <View style={[style.footer, { maxHeight: '80vh' }]}>
        <MonthStrip date={date} setDate={changeDate} />
        <WeekStrip date={date} events={filteredEvents} onChangeValue={setSelectedTimes} />
        <View
          style={[
            style.rowCenter,
            { flex: 0, paddingHorizontal: 20, paddingBottom: useSafeBottomPadding(), minHeight: 'auto' },
          ]}>
          {!listing?.id?.length && (
            <Text style={[{ fontSize: 22, color: Colour.Blue, marginVertical: 10, letterSpacing: 1.1 }]}>
              {selectedTimesHumanReadable(selectedTimes, events !== undefined ? 1 : 4)}
            </Text>
          )}
          <PrimaryButton
            title={listing?.id ? 'MESSAGE' : 'SELECT DATES'}
            disabled={selectedTimes.length === 0 || userIsTheOwner}
            onPress={() => {
              logAnalyticsEvent('calendar_dates_selected', {
                from: selectedTimes[0],
                to: selectedTimes.length > 1 ? selectedTimes[selectedTimes.length - 1] : undefined,
              });
              if (listing?.id) {
                const toDate =
                  selectedTimes.length === 1
                    ? moment(selectedTimes[0]).add(1, 'hour').toISOString()
                    : moment(selectedTimes[selectedTimes.length - 1])
                        .add(1, 'hour')
                        .toISOString();
                linkTo({ screen: 'SendMessage', params: { id: listing.id, fromDate: selectedTimes[0], toDate } });
              } else {
                dispatch(setDateRange(selectedTimes));
                popSafely(navigation);
              }
            }}
          />
        </View>
      </View>
    </LoginBase>
  );
};

export default CalendarScreen;
