import { useMutation } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';

export const useCalendarMutations = () => {
  const { mutate: deleteCalendarConnectNylas } = useMutation({
    mutationFn: apiHandlers.connectCalendar.deleteCalendarConnectionByGrantId,
  });

  return {
    deleteCalendarConnectNylas,
  };
};
